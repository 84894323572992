.table-header-model-1 {
    color: #FFFFFF;
    font-family: "Kanit", sans-serif;
    font-weight: bold;
    font-style: normal;
    background-color: #1b2631;
    font-size: 14px;
}

.table-header-model-1 th {
    align-content: center;
}

.table-row-model-1 {
    // color: #fff;
    font-family: "Kanit", sans-serif;
    font-weight: bold;
    font-style: normal;
    align-content: center;
    font-size: 12px;
}

.table-row-model-1 td {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    cursor: pointer;
}

.table-row-model-1.row-style-1 {
    // background-color: #34495e;
    background-color: #ECF0F1;
}

.table-row-model-1.row-style-1:hover {
    background-color: #3498DB; /* Azul suave */
    color: #FFFFFF; /* Texto blanco para mejor contraste */
}

.table-row-model-1.row-style-2 {
    // color: #000;
    background-color: #FFFFFF;
}

.table-row-model-1.row-style-2:hover {
    background-color: #3498DB; /* Azul suave */
    color: #FFFFFF; /* Texto blanco para mejor contraste */
}

.table-row-model-1 td {
    align-content: center !important;
}

/* Estilo para los botones de paginación */
.table-model-1 .page-item.active .page-link {
    background-color: #34495e;
    border-color: #34495e;
    color: white;
    font-family: "Kanit", sans-serif;
    font-weight: bold;
    font-style: normal;
}

/* Estilo para los botones no activos */
.table-model-1 .page-item .page-link {
    color: #34495e;
    background-color: white;
    border-color: #34495e;
}

/* Estilo para los botones al pasar el mouse */
.table-model-1 .page-item .page-link:hover {
    background-color: #1b2631;
    color: white;
    border-color: #1b2631;
}

/* Estilo para los botones deshabilitados */
.table-model-1 .page-item.disabled .page-link {
    color: gray;
    background-color: white;
    border-color: gray;
}

.table-model-1 .table {
    margin: 0 !important;
}

.status-body {
    color: white !important;
    font-family: "Kanit", sans-serif;
    font-weight: 400;
    font-style: normal;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 25px;
    font-size: 14px !important;
}

.status-blue {
    background-color: #3498db;
}

.status-yellow {
    background-color: #f1c40f;
}

.status-gray {
    background-color: #bdc3c7;
}

.status-orange {
    background-color: #f39c12;
}

.status-green {
    background-color: #27ae60;
}

.react-bootstrap-table-page-btns-ul li {
    font-size: 12px;
}

/* Estilos para el menú */
.menu {
    position: fixed;
    top: 0;
    right: -300px;
    /* Menú oculto fuera de la pantalla */
    width: 300px;
    height: 100%;
    // background-color: #1b2631;
    background-color: #FFFFFF;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    z-index: 2000;
    font-family: "Kanit", sans-serif;
    font-weight: bold;
    font-style: normal;
    // color: #fff;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 26px;
}

/* Menú visible */
.menu.visible {
    right: 0;
    z-index: 2000;
    /* Menú visible en la pantalla */
}

/* Estilos para el menú */
.menu-filter {
    position: fixed;
    top: 0;
    right: -500px;
    /* Menú oculto fuera de la pantalla */
    width: 500px;
    height: 100%;
    // background-color: #1b2631;
    background-color: #FFFFFF;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    z-index: 2000;
    font-family: "Kanit", sans-serif;
    font-weight: bold;
    font-style: normal;
    // color: #fff;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 26px;
}

/* Menú visible */
.menu-filter.visible {
    right: 0;
    z-index: 2000;
    /* Menú visible en la pantalla */
}